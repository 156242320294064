require('./src/css/index.css');

// exports.onClientEntry = () => {
//   const meta = document.createElement('meta');
//   meta.name = 'viewport';
//   meta.content = 'width=device-width, initial-scale=0.6';
//   document.head.appendChild(meta);
//   // console.log("ReactDOM.render has executed")
// };

// gatsby-browser.js

// exports.onInitialClientRender = () => {
//   // Add your class to the body element
//   // document.body.classList.add('dark:bg-slate-900');
// };

